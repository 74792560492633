<template>
  <v-container>
    <v-card class="pa-3" flat dark color="black">
      <v-simple-table
        loading
        loading-text="Loading... Please wait"
      >
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                Id
              </th>
              <th class="text-left">
                Subscription
              </th>
              <th class="text-left">
                Creation time
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in orders"
              :key="item.subscription_id"
            >
              <td>{{ index+1 }}</td>
              <td>{{ item.platforms }}</td>
              <td>{{ item.create_time | moment("MMMM Do YYYY") }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <div v-if="allowCancel" class="text-decoration-underline unsubscribe-text" @click="cancel_sub">
        Cancel Subscription
      </div>
      <div class="load-more">
        <v-btn
          dark
          small
          :loading="loading"
          :disabled="!results.next"
          @click="loadMore"
        >
          load more
          <v-icon>mdi-cached</v-icon>
        </v-btn>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import { getUserOrderList } from '@/api/user'
import { getDeviceFrom } from '@/util/auth'

export default {
  name: 'Orders',
  data() {
    return {
      orders: [],
      results: {},
      loading: true
    }
  },
  computed: {
    allowCancel() {
      const sub = this.$store.state.user.userInfo.subscription
      return sub && ['active', 'trial_2', 'trial_3'].includes(sub.status)
    }
  },
  created() {
    if (['ios', 'android'].includes(getDeviceFrom())) {
      window.Intercom('update', {
        'hide_default_launcher': true
      })
    }
    this.getOrderList()
  },
  methods: {
    getOrderList(params) {
      getUserOrderList(params).then(res => {
        this.results = res
        this.orders = this.orders.concat(res.results)
      }).finally(() => {
        this.loading = false
      })
    },
    loadMore() {
      if (this.results.next) {
        this.loading = true
        const page = this.results.next.split('=')[1]
        this.getOrderList({ page })
      }
    },
    cancel_sub() {
      var device = getDeviceFrom()
      var msg = 'I want to cancel the subscription.'
      if (device === 'ios') {
        window.webkit.messageHandlers.unsubscribe.postMessage(msg)
      } else if (device === 'android') {
        // eslint-disable-next-line no-undef
        AndroidCallback.onShowConfirmDialog(msg)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.orders-card{
    margin-top:unset;
    margin-bottom: unset;
}

.load-more{
    margin-top: 10px;
    text-align: center;
    .v-btn{
        margin: 2px;
    }
}

.unsubscribe-text{
  color: #616161;
  text-align: center;
}
</style>>
